/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WizardTransportStopDTO { 
    transportWizardType?: WizardTransportStopDTO.TransportWizardTypeEnum;
    stopIndex?: number;
    type?: WizardTransportStopDTO.TypeEnum;
    remarks?: string;
    timestamp?: Date;
    locationType?: WizardTransportStopDTO.LocationTypeEnum;
    locationIdentifier?: number;
    goodsIds?: Array<number>;
}
export namespace WizardTransportStopDTO {
    export type TransportWizardTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const TransportWizardTypeEnum = {
        NUMBER_0: 0 as TransportWizardTypeEnum,
        NUMBER_1: 1 as TransportWizardTypeEnum,
        NUMBER_2: 2 as TransportWizardTypeEnum,
        NUMBER_3: 3 as TransportWizardTypeEnum,
        NUMBER_4: 4 as TransportWizardTypeEnum,
        NUMBER_5: 5 as TransportWizardTypeEnum,
        NUMBER_6: 6 as TransportWizardTypeEnum
    };
    export type TypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum,
        NUMBER_3: 3 as TypeEnum,
        NUMBER_4: 4 as TypeEnum,
        NUMBER_5: 5 as TypeEnum,
        NUMBER_6: 6 as TypeEnum,
        NUMBER_7: 7 as TypeEnum,
        NUMBER_8: 8 as TypeEnum
    };
    export type LocationTypeEnum = 0 | 1 | 2 | 3;
    export const LocationTypeEnum = {
        NUMBER_0: 0 as LocationTypeEnum,
        NUMBER_1: 1 as LocationTypeEnum,
        NUMBER_2: 2 as LocationTypeEnum,
        NUMBER_3: 3 as LocationTypeEnum
    };
}